import React, { Component } from 'react'

// import Header from '../components/header'
import '../assets/scss/main.scss'

class Layout extends Component {

  render() {
    let { children } = this.props
    return (
      <>
        {/* <Header/> */}
        <main>
          { children }
        </main>
      </>
    )
  }
}
export default Layout